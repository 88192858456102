<script>
export default {
  props: {
    localEstoque: { type: Object },
    contas: { type: Array },
  },
  data() {
    return {
      
    }
  },
  methods:{
    get_conta(id){
      let res  
      res = this.contas.find((c)=>c.id == id);
      return res.pessoa.pes_apelido;
    },
  },
  computed: {
    indEstoque() {
      switch (this.localEstoque.ind_est) {
        case 1:
          return 'Estoque de propriedade do informante e em posse de terceiros';
        case 2:
          return 'Estoque de propriedade de terceiros e em posse do informante';
        default:
          return 'Estoque de propriedade do informante e em seu poder';
      }
    },
  },
}
</script>

<template>
<div class="localEstoque">
  <p>ID: {{ localEstoque.id }}</p>
  <p>Origem: {{ get_conta(localEstoque.origem_id) }}</p>
  <p>destino: {{ get_conta(localEstoque.destino_id) }}</p>
  <!-- <p>Indicador Estoque: {{ indEstoque }}</p>
  <p>Ativo: <b-badge data-name="status" class="field-status" v-bind:variant="(localEstoque.status) ? 'success' : 'danger'">{{ (localEstoque.status) ? 'Sim' : 'Não' }}</b-badge></p> -->
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ localEstoque.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ localEstoque.updated_at }}</b-badge></p>
</div>
</template>