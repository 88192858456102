<script>
import { indicadorEstoque } from "@/components/defaults/indicadorestoque";
import Multiselect from "vue-multiselect";

export default {
  props: {
    currentEmpresa: { type: Object },
    contas: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      vet_contas: [],
      caixas: [],
      select_de: null,
      select_para: null,
      caixas_conta2: [],
      valor: 0,
      data: null,
      vet_vinculos: [
        {
          id: 1,
          nome: "vinculado",
        },
        {
          id: 2,
          nome: "Não vinculado",
        },
      ],
      select_estado: null,
      select_vinculado: null,
      desc_saida: null,
      desc_entrada: null,
      transf: {
        valor: "0,00",
        select_de: null,
        select_para: null,
        data: null,
        desc_saida: null,
        desc_entrada: null,
        vinculo: null,
        estado: null,
        sit_vinculado: null,
        movimento_caixa_id: 1,
      },
      validar_vic: false,
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0,
      },
      listIndicadores: [],
      loader: {
        get: false,
      },

      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas: [
        {
          id: 1,
          nome: "plano 01",
        },
        {
          id: 2,
          nome: "plano 02",
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      plano: {
        woner_id: null,
        descricao: null,
        natureza: null,
        codigo_reduzido: null,
        classificacao: null,
      },
      origem_obj: null,
      destino_obj: null,
    };
  },
  created() {
    this.vet_contas = this.contas;
  },
  mounted() {
    this.$emit("newTitle", "Tranferencia entre Contas2");
    this.localEstoque.empresa_id = this.currentEmpresa.id;
    this.listIndicadores = indicadorEstoque;
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    verifica_saldo() {
      let valor = this.new_convert_srting_float(this.transf.valor);
      console.log(valor);
      if (this.origem_obj) {
        if(valor > this.origem_obj.saldo){
          this.makeToast('danger','Saldo Insuficiente');
        }
      }
    },
    seleciona_para(event) {
      
      this.transf.origem_id
      if(this.transf.origem_id == event.target.value){
        this.makeToast('danger','Destino e Origem Iguais')
        this.transf.origem_id  =  -1
        this.transf.destino_id = -1
        
      }else{
        let id_conta = event.target.value;
        let obj = this.vet_contas.find((c) => c.id == id_conta);
        this.destino_obj = obj;
      }
      
    },
    seleciona_de(event) {
      let id_conta = event.target.value;
      let obj = this.vet_contas.find((c) => c.id == id_conta);
      this.origem_obj = obj;
    },
    abr_multiselect(valor) {
      console.log("entrar");
      if (valor == 1) {
        console.log(valor);
        this.show_multiselect = true;
      } else {
        console.log("esntrou no elsse" + valor);
        this.show_multiselect = false;
      }
    },
    setPost() {
      // this.loader.get = true;
      // setTimeout(function () {
      //   this.loader.get = false;
      // }, 2000);
      let origem  = {...this.origem_obj}

      if(origem != undefined && origem.saldo > 0){
        
        if(this.transf.valor > origem.saldo){
          this.makeToast("danger","Valor Maior que saldo Disponivel")
        }else{
          this.transf.valor = this.new_convert_srting_float(this.transf.valor);
          this.$emit("doPost", this.transf);
        }
       
      }else{
         this.makeToast("danger","saldo do insuficiente")
      }
     
    },
    selecionar_plano(event) {
      this.plano.woner_id = event.id;
    },
    selecionar_tipos(event) {
      this.plano.classificacao = event.id;
    },
    seleciona_orientacoes(event) {
      this.plano.natureza = event.id;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div
        v-if="this.origem_obj"
        class="row d-flex justify-content-center align-items-center"
      >
        <div class="col-md-6 text-center">
          <div class="card text-white bg-secondary">
            <div class="card-body">
              <span class="badge badge-info">
                {{ "Nome : "
                }}{{
                  this.origem_obj ? this.origem_obj.pessoa.pes_apelido : ""
                }}
              </span>

              <br />
              <span class="badge badge-success">
                {{ "Saldo - "
                }}{{ this.origem_obj ? this.origem_obj.saldo : "" }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="card text-white bg-secondary">
            <div class="card-body">
              <span class="badge badge-info">
                {{ "Nome : "
                }}{{
                  this.destino_obj ? this.destino_obj.pessoa.pes_apelido : ""
                }}
              </span>

              <br />
              <span class="badge badge-success">
                {{ "Saldo - "
                }}{{ this.destino_obj ? this.destino_obj.saldo : "" }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-md-3">
              <Label>Definir Padrão :</Label>
              <input type="text" class="form-control text-left" />
            </div>
            <div class="col-md-3">
              <Label>De :</Label>
              <!-- <Multiselect
              @select="select_origem($event)"
              v-model="select_de"
              :options="caixas_conta2"
              label="apelido"
            ></Multiselect> -->
              <select
                class="form-control"
                v-model="transf.origem_id"
                @change="seleciona_de($event)"
              >
                <option value="-1">selecione um valor</option>
                <option
                  v-for="(item, index) in vet_contas"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.pessoa.pes_apelido }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <Label>Para :</Label>
              <select
                class="form-control"
                v-model="transf.destino_id"
                @change="seleciona_para($event)"
              >
                <option value="-1">selecione um valor</option>
                <option
                  v-for="(item, index) in vet_contas"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.pessoa.pes_apelido }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <Label>{{ "*Data :" }}</Label>
              <input
                v-model="transf.data"
                type="datetime-local"
                class="form-control text-left"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <Label>{{ "Valor:" }}</Label>
              <input
                :disabled="!this.destino_obj && !this.origem_obj"
                v-model="transf.valor"
                type="text"
                class="form-control text-left"
                placeholder="digite o valor"
                @input="
                  formatInputFloat($event, transf, 'valor'), verifica_saldo()
                "
              />
            </div>
            <div class="col-md-4">
              <Label>{{ "*Descrição de Saída na Conta de Origem:" }}</Label>
              <input
                v-model="transf.desc_origem"
                type="text"
                class="form-control text-left"
                placeholder="digite"
              />
            </div>
            <div class="col-md-4">
              <Label>{{ "*Descrição de Entrada na Conta de Origem:" }}</Label>
              <input
                v-model="transf.desc_entrada"
                type="text"
                class="form-control text-left"
                placeholder="digite"
              />
            </div>
          </div>
          <div
            class="row mt-4 d-flex justify-content-center align-items-center"
          >
            <div class="col-md-12 text-center">
              <b-form-checkbox
                v-model="transf.vinculo"
                value="3"
                @change="verifica_vinculo()"
              >
                Vincular a um aviso de Embarque
              </b-form-checkbox>
            </div>
          </div>
          <div
            v-if="validar_vic"
            class="row mt-4 d-flex justify-content-center align-items-center"
          >
            <div class="col-md-6 text-center">
              <multiselect
                @select="selecionar_estado($event)"
                v-model="select_estado"
                :options="estados"
                label="estado"
              >
              </multiselect>
            </div>
            <div class="col-md-6 text-center">
              <multiselect
                @select="selecionar_vinculo($event)"
                v-model="select_vinculado"
                :options="vet_vinculos"
                label="nome"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 text-center">
              <button class="btn btn-secondary" @click.prevent="setPost()">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>